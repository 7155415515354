<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">AGREGAR PRESTACIÓN</v-row>
      <v-container fluid class="addForms" style="margin-top: 2%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select name="reason" id="reason" v-model="enterprise">
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--nombre-->
              <label for="nombre">Nombre</label>
              <input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre de Prestación"
                class="inputs"
                autocomplete="false"
                v-model="nombre"
                maxlength="50"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <!--tipo prestación-->
            <v-row>
              <label for="tipo">Tipo de prestación</label>
              <select name="tipo" id="tipo" v-model="tipo">
                <option selected disabled value="0">Seleccione...</option>
                <option value="1">Ley</option>
                <option value="2">Adicionales</option>
              </select>
            </v-row>
            <!--sdi-->
            <v-row>
              <label for="sdi">Aplica para el SDI</label>
              <select name="sdi" id="sdi" v-model="sdi">
                <option selected disabled value="0">Seleccione...</option>
                <option selected value="1">Sí</option>
                <option value="2">No</option>
              </select>
            </v-row>
            <!--aplica-->
            <v-row>
              <label for="sdi">Como aplica</label>
              <select name="sdi" id="sdi" v-model="aplica" @change="cambio()">
                <option selected disabled value="0">Seleccione...</option>
                <option selected value="1">Monto</option>
                <option value="2">Porcentaje</option>
              </select>
            </v-row>
            <!--aplica-->
            <v-row v-if="aplica == 1">
              <label for="monto">Monto a aplicar</label>
              <input
                type="text"
                name="monto"
                id="monto"
                class="inputs"
                autocomplete="false"
                v-model="monto"
                maxlength="7"
                @keypress="isNumberPoint($event)"
              />
            </v-row>
            <v-row v-if="aplica == 2">
              <!--Fecha inicio-->
              <label for="porcentaje">Porcentaje a aplicar</label>
              <input
                type="text"
                name="porcentaje"
                id="porcentaje"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="porcentaje"
                maxlength="5"
                @keypress="isNumberPoint($event)"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/benefits" id="backBenefit">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Nofificaciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la prestación?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Notificación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="auxiliar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      porcentaje: 0,
      monto: 0,
      tipo: 0,
      sdi: 0,
      nombre: "",
      aplica: 0,
    };
  },
  methods: {
    cambio() {
      if (this.aplica == 1) {
        this.porcentaje = 0;
      } else {
        this.monto = 0;
      }
    },
    isNumberPoint: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 46) {
        return true;
      }
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    auxiliar() {
      this.confirmation = false;
      this.$router.push("/benefits");
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/prestaciones",
          {
            empresaId: this.enterprise,
            nombrePrestacion: this.nombre,
            tipoPrestacion: parseInt(this.tipo),
            aplicaSDI: this.sdi == "1" ? true : false, //parseInt(this.sdi),
            tipoAplicacion: parseInt(this.aplica),
            montoPorcentaje:
              this.aplica == "1"
                ? parseFloat(this.monto)
                : parseFloat(this.porcentaje),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.enterprise = 0;
          this.monto = 0;
          this.porcentaje = 0;
          this.nombre = "";
          this.tipo = 0;
          this.sdi = 0;
          this.aplica = 0;
          this.respuesta = "La Prestación fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = 0;
            this.monto = 0;
            this.porcentaje = 0;
            this.nombre = "";
            this.tipo = 0;
            this.sdi = 0;
            this.aplica = 0;
            this.respuesta = "La prestación no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.enterprise == "" || this.enterprise == " ") {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (this.nombre == "" || this.nombre == " ") {
        this.errors.push("Se debe especificar el nombre de la prestación.");
      }
      if (this.tipo == 0) {
        this.errors.push("Se debe seleccionar el tipo de prestación.");
      }
      if (this.sdi == 0) {
        this.errors.push("Se debe especificar la aplicación a SDI.");
      }
      if (this.aplica == 0) {
        this.errors.push("Se debe especificar como aplica.");
      }
      if (this.aplica == 1 && this.monto == 0) {
        this.errors.push("Se debe especificar el monto.");
      }
      if (this.aplica == 2 && this.porcentaje == 0) {
        this.errors.push("Se debe especificar el porcentaje.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.enterprise = 0;
      this.nombre = "";
      this.tipo = 0;
      this.sdi = 0;
      this.aplica = 0;
      this.monto = 0;
      this.porcentaje = 0;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>